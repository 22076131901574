import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout'

Vue.use(VueRouter)
const routes = [
  /* {
    path: '/',
    name: 'home',
    component: HomeView
  }, */
  {
    path: '/',
    component: Layout,
    redirect: '/',
    name: 'homeView',
    alwaysShow: true,
    menu:0,
    meta: {
      title: '首页',//标题
      icon: 'home',
    },
    children: [
      {
        path: '/',
        component: () => import('@/views/HomeView'),
        name: '首页',
        meta: { title: '首页', menu:[0,1,2,3]}
      }
    ]
  },
  {
    path: '/detail',
    // component: Layout,
    component: () => import('@/views/detail'),
    redirect: '/',
    name: 'detail',
    alwaysShow: false,
    meta: {
      title: 'detail',//标题
      icon: 'home',
    },
    children: [
      {
        path: '/detail',
        component: () => import('@/views/detail'),
        name: '详情页',
        meta: { title: '详情页'}
      }
    ]
  },
  {
    path: '/zhongzhiinfo',
    component: Layout,
    redirect: 'zhongzhiinfo',
    name: 'zhongzhiinfo',
    // component: () => import('@/views/zhongzhinfo/zhongzhiziyuan'),
    alwaysShow: true,
    menu:1,
    meta: {
      title: '种质资源信息',//标题
      icon: 'home',//图标
    },
    children: [
      {
        path: '/ResourceVariety',
        component: () => import('@/views/zhongzhinfo/ziyuanpinzhong'),
        name: '种质资源品种/品系信息',
        meta: { title: '种质资源品种/品系信息',icon: 'pinzhong',menu:[0,1],activeMenu:"/zhongzhiinfo"}
      },
      {
        path: '/PhenotypeData',
        component: () => import('@/views/zhongzhinfo/biaoxingData'),
        name: '表型数据查询及统计分析',
        meta: { title: '表型数据查询及统计分析',icon: 'biaoxin',menu:[0,1] ,activeMenu:"/zhongzhiinfo"}
      },
      {
        path: '/MessageData',
        component: () => import('@/views/zhongzhinfo/snp1'),//走中间页面加载视图
        // component: Layout,
        name: '基因型信息查询与统计分析',
        redirect:"/snp",
        meta: { title: '基因型信息查询与统计分析',icon: 'jiyinxin', menu:[0,1]},
        children: [
          {
            path: '/snp',
            component: () => import('@/views/zhongzhinfo/snp'),
            name: 'snp',
            meta: { title: 'SNP基因型信息查询',menu:[0,1],icon: 'snp',activeMenu:"/zhongzhiinfo"}
          },
          {
            path: '/extraSeq',
            component: () => import('@/views/zhongzhinfo/extraSeq'),
            name: 'extraSeq',
            meta: { title: '一致性序列提取',menu:[0,1],icon: 'extraseq',activeMenu:"/zhongzhiinfo"}
          },
        ]
      },
      {
        path: '/CardData',
        component: () => import('@/views/zhongzhinfo/shenfenzheng'),
        name: '种质资源身份证',
        meta: { title: '种质资源身份证',icon: 'card', menu:[0,1],activeMenu:"/zhongzhiinfo"}
      },
    ]
  },
  {
    path: '/zhongzhijiexi',
    component: Layout,
    redirect: 'zhongzhijiexi',
    name: 'zhongzhijiexi',
    alwaysShow: true,
    menu:2,
    meta: {
      title: '多组学遗传解析',//标题
      icon: 'home',//图标
    },
    children: [
      {
        path: '/SpeciedInfo',
        component: () => import('@/views/zhongzhijiexi/sequence1'),
        name: '物种基因组信息解析',
        redirect:"/sequence",
        meta: { title: '物种基因组信息解析',icon: 'sequence', menu:[0,2]},
        children: [
          {
            path: '/sequence',
            component: () => import('@/views/zhongzhijiexi/sequence'),
            name: 'sequence',
            meta: { title: 'Sequence',menu:[0,2],icon:'sequence1',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/blast',
            component: () => import('@/views/zhongzhijiexi/blast'),
            name: 'blast',
            meta: { title: 'Blast',menu:[0,2],icon:'blast',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/msa',
            component: () => import('@/views/zhongzhijiexi/msa'),
            name: 'msa',
            meta: { title: 'MSA',menu:[0,2],icon:'msa',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/TE',
            component: () => import('@/views/zhongzhijiexi/TE'),
            name: 'TE',
            meta: { title: 'TE',menu:[0,2],icon:'te',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/Jbrowse',
            component: () => import('@/views/zhongzhijiexi/Jbrowse'),
            name: 'Jbrowse',
            meta: { title: 'Jbrowse',menu:[0,2],icon:'jbrowse',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/Function',
            component: () => import('@/views/zhongzhijiexi/search'),
            name: 'Function',
            meta: { title: 'Function',menu:[0,2],icon:'search',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/KEGG',
            component: () => import('@/views/zhongzhijiexi/KEGG'),
            name: 'KEGG',
            meta: { title: 'KEGG',menu:[0,2],icon:'kegg',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/GO',
            component: () => import('@/views/zhongzhijiexi/GO'),
            name: 'GO',
            meta: { title: 'GO',menu:[0,2],icon:'go',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/Pathway',
            component: () => import('@/views/zhongzhijiexi/pathway'),
            name: 'Pathway',
            meta: { title: 'Pathway',menu:[0,2],icon:'pathway',activeMenu:"/zhongzhijiexi"}
          },
        ]
      },
      {
        path: '/Diversity',
        component: () => import('@/views/zhongzhijiexi/structure1'),
        name: '种质遗传多样性',
        meta: { title: '种质遗传多样性',icon: 'Population', menu:[0,2]},
        redirect:"/Structure",
        children: [
          {
            path: '/Structure',
            component: () => import('@/views/zhongzhijiexi/Structure'),
            name: 'Structure',
            meta: { title: 'Structure', menu:[0,2],icon: 'structure',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/Signals-pi',
            component: () => import('@/views/zhongzhijiexi/Signals-pi'),
            name: 'Signals-π',
            meta: { title: 'Signals-π',menu:[0,2],icon: 'pi',activeMenu:"/zhongzhijiexi" }
          },
          {
            path: '/Signals-fst',
            component: () => import('@/views/zhongzhijiexi/Signals-fst'),
            name: 'Signals-fst',
            meta: { title: 'Signals-fst', menu:[0,2],icon: 'fst',activeMenu:"/zhongzhijiexi"}
          },
        ]
      },
      {
        path: '/TraitMapping',
        component: () => import('@/views/zhongzhijiexi/GWAS1'),
        name: '性状定位',
        redirect:"/BSA",
        meta: { title: '性状定位',icon: 'gwas', menu:[0,2]},
        children: [
          {
            path: '/BSA',
            component: () => import('@/views/zhongzhijiexi/BSA'),
            name: 'BSA',
            meta: { title: 'BSA',menu:[0,2] ,icon: 'bsa',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/QTL',
            component: () => import('@/views/zhongzhijiexi/gene'),
            name: 'QTL',
            meta: { title: 'QTL', menu:[0,2],icon: 'qtl1',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/GWAS',
            component: () => import('@/views/zhongzhijiexi/GWAS'),
            name: 'GWAS',
            meta: { title: 'GWAS',menu:[0,2] ,icon: 'gwas1',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/primerDesign',
            component: () => import('@/views/zhongzhijiexi/primerDesign'),
            name: '引物设计',
            meta: { title: '引物设计',menu:[0,2],icon: 'design',activeMenu:"/zhongzhijiexi"}
          },
        ]
      },
      {
        path: '/Allele',
        component: () => import('@/views/zhongzhijiexi/AlleleList'),
        name: '优异等位基因',
        // redirect:"/AlleleList",
        meta: { title: '优异等位基因',icon: 'qtl', menu:[0,2],activeMenu:"/zhongzhijiexi"},
        /* children: [
          {
            path: '/QTL',
            component: () => import('@/views/zhongzhijiexi/gene'),
            name: 'QTL',
            meta: { title: 'QTL', menu:[0,2],activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/AlleleList',
            component: () => import('@/views/zhongzhijiexi/AlleleList'),
            name: 'AlleleList',
            meta: { title: '优异等位基因列表',menu:[0,2] ,activeMenu:"/zhongzhijiexi"}
          },
        ] */
      },
      {
        path: '/Tools',
        component: () => import('@/views/zhongzhijiexi/GeneCoExpression1'),
        name: '常用的多组学工具',
        redirect:"/GeneCoExpression",
        meta: { title: '常用的多组学工具',icon: 'tools', menu:[0,2]},
        children: [
          {
            path: '/GeneCoExpression',
            component: () => import('@/views/zhongzhijiexi/GeneCoExpression'),
            name: 'Gene Co-expression',
            meta: { title: '基因共表达', menu:[0,2],icon: 'expression',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/Deseq2',
            component: () => import('@/views/zhongzhijiexi/Deseq2'),
            name: 'Deseq2',
            meta: { title: '差异基因分析',menu:[0,2] ,icon: 'deseq2',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/PhylogeneticAnalysis',
            component: () => import('@/views/zhongzhijiexi/PhylogeneticAnalysis'),
            name: 'Phylogenetic Analysis',
            meta: { title: '进化分析',menu:[0,2] ,icon: 'analysis',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/WGCNA',
            component: () => import('@/views/zhongzhijiexi/WGCNA'),
            name: 'Wgcna',
            meta: { title: '权重基因共表达网络分析',menu:[0,2] ,icon: 'wgcan',activeMenu:"/zhongzhijiexi"}
          },
        ]
      },
      {
        path: '/PanGenome',
        component: () => import('@/views/zhongzhijiexi/GeneCoExpression1'),
        name: '泛基因组',
        redirect:"/Browse",
        meta: { title: '泛基因组',icon: 'biaoxingshuju', menu:[0,2]},
        children: [
          {
            path: '/Browse',
            component: () => import('@/views/fanjiyinzu/Browse'),
            name: '泛基因组浏览器',
            redirect:"/Browse/wheat55k",
            meta: { title: '泛基因组浏览器', menu:[0,2],icon: 'jbrowse'}
          },
          {
            path: '/GeneSearch',
            component: () => import('@/views/fanjiyinzu/GeneSearch'),
            name: '基因查询',
            meta: { title: '基因查询', menu:[0,2],icon: 'analysis',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/synvisio',
            component: () => import('@/views/fanjiyinzu/synvisio'),
            name: '基因组共线性',
            meta: { title: '基因组共线性', menu:[0,2],icon: 'jiyinxin',activeMenu:"/zhongzhijiexi"}
          },
          {
            path: '/PanGWAS',
            component: () => import('@/views/zhongzhijiexi/GWAS1'),
            name: '泛基因组GWAS',
            // redirect:"/PanGWAS/SNPGWAS",
            meta: { title: '泛基因组GWAS', menu:[0,2],icon: 'pi'},
            children: [
              {
                path: '/PanGWAS/SNPGWAS',
                component: () => import('@/views/fanjiyinzu/SNPGWAS'),
                name: 'SNP-GWAS',
                meta: { title: 'SNP-GWAS', menu:[0,2],icon: 'gwas1',activeMenu:"/zhongzhijiexi"}
              },
              {
                path: '/PanGWAS/SVGWAS',
                component: () => import('@/views/fanjiyinzu/SVGWAS'),
                name: 'SV-GWAS',
                meta: { title: 'SV-GWAS', menu:[0,2],icon: 'qtl',activeMenu:"/zhongzhijiexi"}
              },
            ]
          },
          {
            path: '/MutationQuery',
            component: () => import('@/views/fanjiyinzu/bianyiSearch'),
            name: '变异查询',
            meta: { title: '变异查询', menu:[0,2],icon: 'deseq2',activeMenu:"/zhongzhijiexi"}
          },
        ]
      },
    ]
  },
  /* {
    path: '/PanGenome',
    component: Layout,
    redirect: 'fanjiyinzu',
    name: 'fanjiyinzu',
    alwaysShow: true,
    menu:4,
    meta: {
      title: '泛基因组',//标题
      icon: 'home',//图标
    },
    children: [
      {
        path: '/Browse/wheat55k',
        component: () => import('@/views/yuzhongyuce/Phenotype1'),
        name: '泛基因组浏览器',
        // redirect:"/Browse/wheat55k",
        meta: { title: '泛基因组浏览器', menu:[0,4],icon: 'pi'}
      },
      {
        path: '/GeneSearch',
        component: () => import('@/views/fanjiyinzu/GeneSearch'),
        name: '基因查询',
        meta: { title: '基因查询', menu:[0,4],icon: 'pi',activeMenu:"/fanjiyinzu"}
      },
      {
        path: '/synvisio',
        component: () => import('@/views/fanjiyinzu/synvisio'),
        name: '基因组共线性',
        meta: { title: '基因组共线性', menu:[0,4],icon: 'pi',activeMenu:"/fanjiyinzu"}//,href:'http://192.168.120.87:8071/#/http://192.168.120.87:8021/api/file/smart_gsd/synvisio/at_vv'
      },
    ]
  }, */
  {
    path: '/yuzhongyuce',
    component: Layout,
    redirect: 'kaifa',
    name: 'yuzhongyuce',
    alwaysShow: true,
    menu:3,
    meta: {
      title: '智能育种设计',//标题
      icon: 'home',//图标
    },
    children: [
      {
        path: '/kaifa',
        component: () => import('@/views/yuzhongyuce/kaifa'),
        name: 'kaifa',
        meta: { title: '智能育种设计', menu:[0,3],icon: 'expression',activeMenu:"/yuzhongyuce"}
      },
      /* {
        path: '/biaoxingshuju',
        component: () => import('@/views/yuzhongyuce/Phenotype1'),
        name: '表型数据',
        redirect:"/Phenotype",
        meta: { title: '表型数据',icon: 'biaoxingshuju', menu:[0,3]},
        children: [
          {
            path: '/Phenotype',
            component: () => import('@/views/yuzhongyuce/Phenotype'),
            name: 'Phenotype',
            meta: { title: '表型分布统计', menu:[0,3],icon: 'expression',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/Difference',
            component: () => import('@/views/yuzhongyuce/Difference'),
            name: 'Difference',
            meta: { title: '表型异常值处理',menu:[0,3] ,icon: 'deseq2',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/TechnicalDuplication',
            component: () => import('@/views/yuzhongyuce/TechnicalDuplication'),
            name: 'TechnicalDuplication',
            meta: { title: '技术重复处理',menu:[0,3] ,icon: 'analysis',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/BLUP',
            component: () => import('@/views/yuzhongyuce/BLUP'),
            name: 'BLUP',
            meta: { title: '多年多点的BLUP值估算',menu:[0,3] ,icon: 'msa',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/ability',
            component: () => import('@/views/yuzhongyuce/peiheli'),
            name: 'ability',
            meta: { title: '表型的一般配合力',menu:[0,3] ,icon: 'search',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/heritability',
            component: () => import('@/views/yuzhongyuce/yichuanli'),
            name: 'heritability',
            meta: { title: '表型的遗传力计算',menu:[0,3] ,icon: 'wgcan',activeMenu:"/yuzhongyuce"}
          },
        ]
      },
      {
        path: '/GermplasmScreening',
        component: () => import('@/views/yuzhongyuce/biaojishaixuan1'),
        name: '核心标记筛选',
        redirect:"/TagFilterMetrics",
        meta: { title: '核心标记筛选',icon: 'pinzhong', menu:[0,3]},
        children: [
          {
            path: '/TagFilterMetrics',
            component: () => import('@/views/yuzhongyuce/biaojishaixuan'),
            name: 'TagFilterMetrics',
            meta: { title: '标记筛选指标', menu:[0,3],icon: 'pi',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/Chromosome',
            component: () => import('@/views/yuzhongyuce/Chromosome'),
            name: 'Chromosome',
            meta: { title: '核心标记的染色体分布',menu:[0,3] ,icon: 'fst',activeMenu:"/yuzhongyuce"}
          },
        ]
      },
      
      {
        path: '/GSModel',
        component: () => import('@/views/yuzhongyuce/biaojishaixuan1'),
        name: '核心种质筛选',
        redirect:"/Saturation",
        meta: { title: '核心种质筛选',icon: 'biaoxin', menu:[0,3]},
        children: [
          {
            path: '/Saturation',
            component: () => import('@/views/yuzhongyuce/baohe'),
            name: 'Saturation',
            meta: { title: '核心种质数量的饱和曲线', menu:[0,3],icon: 'analysis',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/CoreGermplasmAnalysis',
            component: () => import('@/views/yuzhongyuce/zhongzhifenxi'),
            name: 'Core germplasm analysis',
            meta: { title: '核心种质分析',menu:[0,3] ,icon: 'Population',activeMenu:"/yuzhongyuce"}
          },
        ]
      },
      {
        path: '/BreedingPrediction',
        component: () => import('@/views/yuzhongyuce/biaojishaixuan1'),
        name: '全基因组选择GS预测',
        redirect:"/trainingSites",
        meta: { title: '全基因组选择GS预测',icon: 'jiyinxin', menu:[0,3]},
        children: [
          {
            path: '/trainingSites',
            component: () => import('@/views/yuzhongyuce/xulianQTL'),
            name: 'training sites',
            meta: { title: '训练位点染色体分布统计',menu:[0,3] ,icon: 'deseq2',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/Chip',
            component: () => import('@/views/yuzhongyuce/chip'),
            name: 'chip',
            meta: { title: '芯片设计与定制',menu:[0,3] ,icon: 'deseq2',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/GSAlgorithm',
            component: () => import('@/views/yuzhongyuce/GSAlgorithm'),
            name: 'GSAlgorithm',
            meta: { title: 'GS算法', menu:[0,3],icon: 'expression',activeMenu:"/yuzhongyuce"},
          },
        ]

      },
      {
        path: '/FunctionPointAnalysis',
        component: () => import('@/views/yuzhongyuce/biaojishaixuan1'),
        name: '功能点位解析',
        redirect:"/DominantAllele",
        meta: { title: '功能点位解析',icon: 'jiyinxin', menu:[0,3]},
        children: [
          {
            path: '/DominantAllele',
            component: () => import('@/views/yuzhongyuce/DominantAllele'),
            name: 'Dominant Allele',
            meta: { title: '优势等位基因',menu:[0,3] ,icon: 'deseq2',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/estimation',
            component: () => import('@/views/yuzhongyuce/estimation'),
            name: 'estimation',
            meta: { title: '上位效应估算',menu:[0,3] ,icon: 'deseq2',activeMenu:"/yuzhongyuce"}
          },
          {
            path: '/PVE',
            component: () => import('@/views/yuzhongyuce/PVE'),
            name: 'PVE',
            meta: { title: '表型解释率PVE', menu:[0,3],icon: 'expression',activeMenu:"/yuzhongyuce"},
          },
        ]

      },
      
      {
        path: '/BreedingDesign',
        component: () => import('@/views/yuzhongyuce/fangan'),
        name: '育种设计',
        meta: { title: '育种设计方案',icon: 'card', menu:[0,3]},
      }, */
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
export {
  routes
}
