import request from '@/utils/request'
// 引入axios
import axios from 'axios'
// 请求延时（毫秒数，如果请求话费超过了'timeout'的时间，请求将被中断）
axios.defaults.timeout = 100000
// 请求体
export function getPost(url,data) {
    return request({
        url: url,
        method: 'post',
        data,
    })
}
export function getSearch(url,data) {
    return request({
        url: url,
        method: 'get',
        params:data
    });
}
export function GermplasmUpList(data) {

    return request({
      url: '/task/germplasm_card/',
      headers: {
        "Content-Type": "multipart/form-data",
      },
      processData:false,
      method: 'post',
      data
    })
  }
  // blast 任务接口参数
export function blastParams(url) {
  return request({
      url: '/mongo/blast_params/',
      method: 'get',
      // params:data,//get请求参数
  })
}
//tools-RepeatMasker
export function te_rm(data) {
  return request({
      url: '/mongo/te_rm/',
      method: 'post',
      data,
  })
}
//tools-RepeatProteinMask
export function te_rpm(data) {
  return request({
      url: '/mongo/te_rpm/',
      method: 'post',
      data,
  })
}
//jbrowse
export function jbrowse(url) {
  return request({
      url: '/mongo/jbrowse/',
      // url: '/mongo/jbrowse/',
      method: 'get',
      // params:data,//get请求参数
  })
}
  axios.defaults.baseURL="/api"

  
