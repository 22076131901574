import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    request_list: [],
  },
  getters: {
  },
  mutations: {
    set_request_url(state, cancel) {
      state.request_list.push(cancel);
    },
    clear_request(state) {
      state.request_list.map((item) => {
        item.cancelToken("请求终止");
      });
    },
  },
  actions: {
  },
  modules: {
  }
})
