<template>
  <div>
    <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;"> -->
      <el-button class="button" @click="isCollapseFun" :class="{'is-active':isCollapse}" ><i ><svg-icon :data_iconName="isCollapse?'menu':'menu1'" :className="isCollapse?'menu_icon':'menu1_icon'" class="svgIcon" style="width: 0.05rem;"/></i></el-button>
<!-- </el-radio-group> -->
        <el-menu
          :default-active="navLeftActive"
          class="el-menu-demo menu1"
          :style="{width:!isCollapse?'auto':'100px',overflow:!isCollapse?'auto':'hidden',overflowX:'hidden'}"
          background-color="rgb(0 0 0 / 0%)"
          active-background-color="#007635"
          :router="true"
          :collapse-transition="false"
          :collapse="isCollapse"
        >
          <!-- :active-text-color="color=='rgb(15 15 15)'?'#0343dd':'#a0bcff'" -->
          <!-- :text-color="color" -->
          <template v-for="(item, index) in routerList">
            <el-submenu
              :index="item.path"
              :key="index"
              v-if="item.children ? item.children.length != 1 : ''"
              class="submenu"
            >
              <template slot="title">
                <i>
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i>
                <span slot="title">{{ item.meta.title }}</span>
              </template>
              <div  v-for="(itm, inx) in item.children" :key="inx">
                <el-menu-item
                  :index="itm.path"
                  v-if="!itm.children"
                  ><template slot="title">
                  <i>
                <svg-icon :data_iconName="itm.meta.icon" :className="itm.meta.icon+'_icon'" class="svgIcon"
              /></i>
                  <!-- <a :href="itm.meta.href" v-if="itm.meta.href"><span slot="title">{{ itm.meta.title }}</span></a> -->
                  <span slot="title" >{{ itm.meta.title }}</span>
  
                </template>
                </el-menu-item>
                <el-submenu
                  :index="itm.path"
                  :key="inx"
                  class="submenu"
                  v-else
                >
                <template slot="title">
                <i>
                  <svg-icon :data_iconName="itm.meta.icon" :className="itm.meta.icon+'_icon'" class="svgIcon"
                /></i>
                    <span slot="title">{{ itm.meta.title }}</span>
                </template>
                <el-menu-item
                  :index="it.path"
                  v-for="(it,ix) in itm.children"
                  :key="ix"
                  ><template slot="title">
                  <i>
                <svg-icon :data_iconName="it.meta.icon" :className="it.meta.icon+'_icon'" class="svgIcon"
              /></i>
                  <!-- <a :href="itm.meta.href" v-if="itm.meta.href"><span slot="title">{{ itm.meta.title }}</span></a> -->
                  <span slot="title" >{{ it.meta.title }}</span>
  
                </template>
                </el-menu-item>
                </el-submenu>
              </div>
              <!-- <i>
              <svg-icon :data_iconName="item.children.meta.icon" :className="item.children.meta.icon+'_icon'" class="svgIcon"
            /></i> -->

            </el-submenu>
            <el-menu-item
              :index="item.meta.menu != 1 ? item.path : item.children[0].path"
              v-if="
                !item.children
              "
              :key="index"
            >
              <!-- :index="item.children[0].path" -->
              <!-- <template slot="title"> -->
              <i class="marginRight svgI" >
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i>
              <span slot="title">{{ item.meta.title }}</span>
              <!-- </template> -->
            </el-menu-item>
          </template>
        </el-menu>
      <!-- </el-col> -->
      <!-- <el-col :span="4">
        <div class="login">
          <el-button>登录</el-button>
          <el-button>注册</el-button>
        </div>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  name: "HelloWorld",
  props:{
   //传值
   },
  data() {
    return {
      activeIndex: "1",
      name: this.$Storage.getItem("adm_name"),
      routerList: [],
      isCollapse: false,
    };
  },
  components:{
    //引入模块
  },
  methods: {
    getMenuLinks() {
      console.log(this.$router.options.routes,this.$route)
      var menuList=[]
      for(var i=0;i<this.$router.options.routes.length;i++){
        for(var a=0;a<this.$router.options.routes[i].children.length;a++){
          if(this.$router.options.routes[i].children[a].children){
            for(var b=0;b<this.$router.options.routes[i].children[a].children.length;b++){
              if(this.$router.options.routes[i].children[a].children[b].path==this.$route.path){
                menuList=this.$router.options.routes[i].children
              }
              if(this.$router.options.routes[i].children[a].children[b].children){
                for(var c=0;c<this.$router.options.routes[i].children[a].children[b].children.length;c++){
                  if(this.$router.options.routes[i].children[a].children[b].children[c].path==this.$route.path){
                    menuList=this.$router.options.routes[i].children
                  }
                }
              }
              
            }
          }else{
            if(this.$router.options.routes[i].children[a].path==this.$route.path){
            menuList=this.$router.options.routes[i].children
          }
          }
          
        }
       
      }
      console.log(menuList)
      // console.log(menuList)
      // var t = this.$router.options.routes.filter(function (val, index, arr) {
      //   // //console.log(val)
      //   return val.children;
      // });
      // this.routerList = t;
      // // //console.log(this.$router,this.$route);
      
      
      // var list=this.$route.meta.menu
      // console.log(list,"list")
      // var arr=this.$router.options.routes.filter(function (val) {
      //   //console.log(val)
      //   return val
      // })
      /* if(list.length==2){
        var arr1=[]
        for(var i=0;i<arr.length;i++){
          if(i==0){
            arr1.push(arr[i])
          }else{
            for(var o=0;o<arr[i].children.length;o++){
              if(arr[i].children[o].children){
                arr1.push({alwaysShow:false,children:arr[i].children[o].children,component:arr[i].component,menu:arr[i].menu,meta:arr[i].children[o].meta,name:arr[i].children[o].name,path:arr[i].children[o].path,redirect:arr[i].children[o].redirect})
              }else{
                arr1.push({alwaysShow:arr[i].alwaysShow,children:[arr[i].children[o]],component:arr[i].component,menu:arr[i].menu,meta:arr[i].children[o].meta,name:arr[i].children[o].name,path:arr[i].children[o].path,redirect:arr[i].children[o].path})
              }
              
            }
          }
        }
         this.routerList = arr1; */
      // }else{
        //  this.routerList = menuList;
         this.$set(this,'routerList',menuList)
         this.$forceUpdate()
      // }
      // console.log(menuList)
      
    },
    isCollapseFun(){
      this.isCollapse=!this.isCollapse
    },
    refresh() {
      this.reload();
    },
    login() {
      this.$router.push({ path: "/login" });
    },
    logout() {
      this.$Storage.removeItem("adm_name");
      this.$Storage.removeItem("Admin-Token");
      this.name = this.$Storage.getItem("adm_name");
    },
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    navLeftActive: function () {
      //console.log(this.$route);
      const { meta, path } = this.$route; 
      console.log(meta, path, this.$route, 111);
       this.getMenuLinks();
      if (meta.activeMenu) {
        return path;
      }
     
      return path; //"/" + path.split("/")[1];
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    this.getMenuLinks();
  },
  watch: {
    /* $route(to, from) {
        window.location.reload(); //监测到路由发生跳转时刷新一次页面
    }, */
  },
};
</script>

<style scoped>
.menu1 {
  height: calc(100vh - 60px);
  
}
.el-menu--horizontal {
  border: 0px !important;
}
.login {
  /* float: right; */
  /* margin-right: 200px; */
}
.el-menu-item.is-active{
  color: #007635;
  font-weight: bold;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 2px solid green;
}
.el-menu--horizontal>.el-menu-item{
  height: 60px !important;
  line-height: 60px !important;
}
/* .el-menu--horizontal .el-menu .el-menu-item:hover{
  color: black !important;
  background: rgba(8, 138, 105, 0.455) !important;
} */
.el-menu--horizontal .el-menu .el-menu-item{
  color: rgb(252, 251, 251) !important;
  background-color: #007635 !important;
  /* font-size: 12px !important; */
  /* border-bottom: 2px dashed rgba(0, 128, 0, 0.719); */
}
.button{
  border: 0;
  /* background: transparent !important; */
  position: fixed;
    left: 270px;/*no*/
    top: 50%;
    height: 100px;
    background: #eee!important;
    padding: 0;
    border-radius: 0 20px 20px 0;
    margin-top: -100px;
    z-index: 10000;
}
.button.is-active {
  /* transform: rotate(90deg); */
  left: 100px !important;/*no*/
}
.button:hover{
  background-color: #eee !important;
}
.menu1>>>.el-submenu__title{
  min-width: 250px !important;
}
.submenu>>>.el-submenu__title{
  padding-left: 40px !important;/*no*/
}
.submenu>>>.el-menu{
      padding-left: 20px !important;/*no*/
}
.menu1>>>.el-menu-item{
  padding-left: 40px !important;/*no*/
}
.menu1>>>.el-tooltip{
  left: 20px !important;/*no*/
}
</style>
