import Vue from 'vue'
import App from './App.vue'
import router,{routes} from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'   //样式表
import enLang from 'element-ui/lib/locale/lang/zh-CN'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖import setStorage from '@/utils/Storge'/* 封装缓存 */
import '@/svgIcon/index.js'
import '@/utils/lib-flexible'
// import '@/components/map'
import setStorage from '@/utils/Storge'/* 封装缓存 */
Vue.prototype.$Storage=setStorage  
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { RecycleScroller } from 'vue-virtual-scroller'
// 引入echarts
import * as echarts from "echarts";
import axios from 'axios'
Vue.prototype.$echarts = echarts;

//获取 外部config.json 
Vue.prototype.$http = axios;

Vue.prototype.getConfig = function () {
  //this.$http.get('../static/config.json').then(res => {
  this.$http.get('../zh.json').then((res)=>{
    Vue.prototype.$ZH = res.data;
    // console.log('11111111');
    console.log(Vue.prototype.apiUrl);
  }).catch(err => {
    console.log(err);
    //console.log('22222');
  })
}


Vue.component('RecycleScroller', RecycleScroller)
Vue.use(Element, {
  size: 'medium', // set element-ui default size
  /* locale: {el:{...enLang.el,pagination:{
    pagesize:'rows/page',
    total: `Totally {total} rows`,

        goto: 'goto',

        pageClassifier: 'page'
  }}} */ // 如果使用中文，无需设置，请删除
})

Vue.config.productionTip = false
import {jbrowse} from "@/api/dataList"
import VueRouter from 'vue-router'
jbrowse().then((res)=>{
  // console.log(res,"jbrowse")
  if(res.data.code==1){
    const newRoutes = routes
    /* this.jbrowseList = res.data.species.map((item) => {
      return { label: item.value, value: item.label };
    }); */
    // console.log(newRoutes,"11")
    var label=[]
    var url=[]
    var arr=res.data.data//.filter((item,index)=>index<5)
    // console.log(arr)
    for(var key in arr){
      
      label.push({
          path: '/Browse/'+arr[key].label,
          component: () => import('@/views/fanjiyinzu/Browse'),
          name: arr[key].label,
          meta: {activeMenu:"/zhongzhijiexi",icon:"",menu:[0,2], title: arr[key].label, url:arr[key].url}
      })
    }
    newRoutes[3].children[5].children[0].children=label
    const newRouter = new VueRouter({
      mode: 'hash',
      routes: newRoutes
    })
    // this.$forcepdate()
    router.matcher=newRouter.matcher
    // console.log( routes,"222")
    // router.addRoutes(newRoutes);
   
  }
})


Vue.directive('loadmore', {
  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function() {
      let sign = 0
      const scrollDistance = this.scrollWidth - this.scrollLeft - this.clientWidth
      if (scrollDistance <= sign) {
        
        binding.value()
        
      }
    })
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
