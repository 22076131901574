<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
//import TopNav from "@/components/TopNav/index.vue";
import MenuTab from "@/components/MenuTab/index.vue";
export default {
  name: "app",
  provide() {
    // 注册一个方法
    return {
      reload: this.reload, //  页面刷新
    };
  },
  data() {
    return {
      //需要占满整个屏幕的路由
      routeData: ["login"],
      isRouterAlive: true,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "margin:0;");
  },
  components: {
    //TopNav,
    MenuTab,
   
  },
  mounted(){
    this.getConfig()
  },
  methods: {
    //刷新功能
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
}
</style>